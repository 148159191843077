<template>
    <div class="threeDS-page"></div>
</template>

<script>
import './threeDS-page.scss'

import Store from '@index/store'

import threeDS2 from '@index/api/threeDS2'

export default {
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            let {
                data,
                card
            } = this.$route.params

            if (!['3ds_2', '3ds'].includes(data.type)) {
                this.showError();
                return;
            }

            this.$store.commit('application/load', true);

            if (data.type === '3ds_2') {
                const frame = document.createElement('iframe')
                const path = window.location.origin +
                    '/api/tinkoff_3ds_2?ThreeDSMethodURL=' +
                    data.ThreeDSMethodURL +
                    '&threeDSMethodData=' +
                    data.threeDSMethodData

                frame.setAttribute('src', path)
                frame.setAttribute('class', 'threeDS-page__invisible-frame')
                this.$el.appendChild(frame)

                const threeDS2Data = await (() => new Promise(resolve => {
                    setTimeout(async () => {
                        const threeDS2Data = await threeDS2({
                            ...card,
                            ...data.dataBack,
                            language: navigator.language,
                            // @TODO узкое место чекнуть в caniuse
                            timezone: new Date().getTimezoneOffset(),
                            screen_width: screen.width,
                            screen_height: screen.height,
                        })
                        resolve(threeDS2Data)
                    }, 2500)
                }))()

                data = {
                    ...data,
                    ...threeDS2Data
                }
            }

            // @TODO doRequestAdditCheck

            const frame = document.createElement('iframe')
            const frameSrc = `/api/${data.type}?action=${data.param}`

            frame.setAttribute('src', frameSrc)
            this.$el.appendChild(frame)
            this.$store.commit('application/load', false)

            // @TODO проверка на 10 сек загрузка 3ds
        },
        showError() {
            this.$store.commit('error/showError')
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === null) {
            Store.commit('application/load', false)
            next({
                path: '/'
            })

            return;
        }

        const {
            data: {
                type
            } = {}
        } = to.params

        if (!type) {
            // @TODO отрефачить это говно Придумать систему id
            Store.commit('application/load', false)
            Store.commit('error/showError', {
                title: 'Ошибка проверки карты',
                description: 'Мы не смогли проверить вашу карту.<br/> Попробуйте еще раз, или используйте другую',
                action: 'Перейти'
            })
            return;
        }

        if (type !== 'authorized') {
            next()
        } else {
            window.open('/final', '_self')
        }
    }
}
</script>